var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('editor',{attrs:{"api-key":_vm.apiKey,"init":{
  height: _vm.height,
  min_height: _vm.minHeight,
  menubar: _vm.menuBar,
  plugins: _vm.plugins,
  toolbar: _vm.toolBarComputed,
  file_picker_types: 'image',
  images_files_types: 'jpeg,jpg,png',
  file_picker_callback: _vm.filePickerCallback,
  relative_urls: false,
  remove_script_host: false,
  document_base_url: _vm.documentBaseUrl,

  setup: function (editor) {
    editor.ui.registry.addMenuButton('aommergetags', {
      text: 'Merge Tags',
      fetch: function (callback) {
        var items = Object.values(_vm.mergeTags).map(function (mergeTag) { return ({
          'type': "menuitem",
          text: mergeTag.text,
          onAction: function () {
            editor.insertContent(mergeTag.tag);
          },
        }); });
        callback(items);
      },
    });
  },
},"placeholder":_vm.placeholder},on:{"input":_vm.updateEvents},model:{value:(_vm.richText),callback:function ($$v) {_vm.richText=$$v},expression:"richText"}})}
var staticRenderFns = []

export { render, staticRenderFns }