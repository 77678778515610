<template>
  <editor v-model="richText" :api-key="apiKey" :init="{
    height: height,
    min_height: minHeight,
    menubar: menuBar,
    plugins: plugins,
    toolbar: toolBarComputed,
    file_picker_types: 'image',
    images_files_types: 'jpeg,jpg,png',
    file_picker_callback: filePickerCallback,
    relative_urls: false,
    remove_script_host: false,
    document_base_url: documentBaseUrl,

    setup: (editor) => {
      editor.ui.registry.addMenuButton('aommergetags', {
        text: 'Merge Tags',
        fetch: (callback) => {
          var items = Object.values(mergeTags).map((mergeTag) => ({
            'type': `menuitem`,
            text: mergeTag.text,
            onAction: function () {
              editor.insertContent(mergeTag.tag);
            },
          }));
          callback(items);
        },
      });
    },
  }" :placeholder="placeholder" @input="updateEvents" />
</template>

<script>
/* eslint-disable no-unused-vars */
const tinyMceToolbar =
  "aommergetags | undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | link image | help ";
const tinyMcePlugins = [
  "advlist",
  "autolink",
  "lists",
  "link",
  "image",
  "charmap",
  "print",
  "preview",
  "anchor",
  "searchreplace",
  "visualblocks",
  "code",
  "fullscreen",
  "autoresize",
  "insertdatetime",
  "media",
  "table",
  "paste",
  "code",
  "help",
  "wordcount",
];

import Editor from "@tinymce/tinymce-vue";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";
import { mergeTags as MERGETAGS } from "@/models/enums/mergeTags";
import { emailComposeTypes } from "@/models";
import { AOM_MAIN_DOMAIN } from "@/constants/app";
import imagesService from "@/services/imagesService";

export default {
  components: {
    editor: Editor,
  },
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 500,
    },
    minHeight: {
      type: Number,
      default: 200,
    },
    menuBar: {
      type: Boolean,
      default: false,
    },
    toolBar: {
      type: String,
      default: tinyMceToolbar,
    },
    showImage: {
      type: Boolean,
      default: false
    },
    plugins: {
      validator(value) {
        return Array.isArray(value)
          ? value.every(v => tinyMcePlugins.indexOf(v) !== -1)
          : tinyMcePlugins.indexOf(value) !== -1;
      },
      default: () => tinyMcePlugins,
    },
    type: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Compose a message...",
    },
  },
  computed: {
    toolBarComputed() {
      if (!this.showImage) {
        return this.toolBar.replace('image', '')
      }
      return this.toolBar;
    }
  },
  data() {
    return {
      richText: "",
      mergeTags: MERGETAGS,
      documentBaseUrl: "",
    };
  },
  created() {
    this.documentBaseUrl = `${window.location.protocol}//${AOM_MAIN_DOMAIN}`;
    this.richText = this.value;
    if (this.type === emailComposeTypes.INVITES) {
      this.$watch("value", function () {
        this.richText = this.value;
      });
      let {
        MERGE_TAG_FIRST_NAME,
        MERGE_TAG_LAST_NAME,
        MERGE_TAG_PHONE,
        MERGE_TAG_EMAIL,
        MERGE_TAG_APPLY_BUTTON,
        ...tags
      } = MERGETAGS;
      this.mergeTags = tags;
    } else if (this.type === emailComposeTypes.INVITE_TEMPLATES) {
      let {
        MERGE_TAG_FIRST_NAME,
        MERGE_TAG_LAST_NAME,
        MERGE_TAG_PHONE,
        ...tags
      } = MERGETAGS;
      this.mergeTags = tags;
    }
  },
  methods: {
    // eslint-disable-next-line
    filePickerCallback(callback, value, meta) {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.addEventListener("change", async (e) => {
        const file = e.target.files[0];
        const ALLOWED_TYPES = ["image/jpeg", "image/png"];
        const MAX_SIZE_UPLOAD = 10 * 1024 * 1024; // 5MB

        if (!file || file.size > MAX_SIZE_UPLOAD || !ALLOWED_TYPES.includes(file.type)) {
          alert("Please upload a valid image (JPEG/PNG, max 10MB)");
          return;
        }

        try {
          const formData = new FormData();
          formData.append("file", file);

          // Replace this URL with your API endpoint
          const response = await imagesService.uploadPublicImage(formData);

          if (!response.data?.url) {
            throw new Error("Failed to upload image");
          }
          const imageUrl = response.data.url;

          // Pass the uploaded image URL to TinyMCE callback
          callback(imageUrl, { title: "" });
        } catch (error) {
          alert("Image upload failed. Please try again.");
        }
      });
      input.click();
    },
    updateEvents() {
      this.$emit("update", this.richText);
      this.$root.$emit("text-editor", this.richText);
    },
  },
  setup() {
    const apiKey = `${process.env.VUE_APP_TINY_MCE_API_KEY}`;
    return {
      apiKey,
    };
  },
};
</script>