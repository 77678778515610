import client from './http';

class ImagesService {
  constructor(axios) {
    this.axios = axios;
  }
  uploadPublicImage(data) {
    return this.axios.post('/image/public', data);
  }
}

const imagesService = new ImagesService(client);
export default imagesService;
