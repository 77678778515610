<template>
  <b-modal
    id="modal-add-resource"
    v-model="showModal"
    title="Add Communications"
    centered
    ok-title="Submit"
    cancel-variant="outline-secondary"
    :ok-disabled="isSubmitting"
    @hidden="onHidden"
    @ok.prevent="onSubmit"
  >
    <b-card>
      <b-form>
        <v-select
          v-model="selected"
          multiple
          class="mt-1"
          :dir="dir"
          placeholder="Select Communications"
          :options="options"
          :loading="isLoading"
          transition="fade"
          @search="debounceInput"
        />
      </b-form>
    </b-card>
  </b-modal>
</template>

<script>
import { BCard, BForm, BModal } from "bootstrap-vue";
import { makeErrorToast } from "@/libs/utils";
import _debounce from "lodash/debounce";
import vSelect from "vue-select";
import { commsService }  from "@/services";
import { programTypes } from "@/models";

export default {
  components: {
    BCard,
    BForm,
    BModal,
    vSelect,
  },
  props: {
    showModal: {
      type: Boolean
    },
    program: {
      required: false,
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      keyword: null,
      selected: [],
      options: [],
      dir: null,
      isLoading: false,
    };
  },
  computed: {
    isSubmitting() {
      if (this.selected && this.selected.length) {
        return false;
      }
      return true;
    },
    isProgramTypeTraining() {
      return this.program?.type_id === programTypes.TRAINING;
    },
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    onSubmit() {
      this.$emit("confirmAddComms", [...this.selected]);
      this.$emit("hiddenModal", false);
      this.clearForm();
    },
    onHidden() {
      this.$emit("hiddenModal", false);
      this.clearForm();
    },
    clearForm() {
      this.selected = [];
    },
    debounceInput: _debounce(function (value) {
      this.keyword = value;
      this.loadItems();
    }, 500),
    async loadItems() {
      this.isLoading = true;
      let columnFilters = [];
      if (this.isProgramTypeTraining) {
        columnFilters = [
          ...columnFilters,
          {
            field: "is_training",
            value: 1,
          }
        ];
      }
      if (this.keyword) {
        columnFilters = [
          ...columnFilters,
          {
            field: "messages.subject",
            value: this.keyword,
          }
        ];
      }
      try {
        const response = await commsService.getCommsScheduled(
          {
            columnFilters: columnFilters
          }
        );
        this.options = response.data.items.map(i => ({
          ...i,
          id: i.id,
          label: i.messages ? i.messages[0].subject : ''
        }));
      } catch (e) {
        this.$toast(makeErrorToast("Communications list not loaded."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
